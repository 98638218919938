.App {
  /* text-align: center; */
}

#wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: calc( 100vw - 40px );
  height: calc( 100vh - 40px );
  top: 20px;
  left: 20px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

#mobileOverlay{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #f2f2f2;
}

#mobileType{
  margin: 25px;
  text-align: center;
  text-decoration: none;
  color: #333333;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

#viewPanel{
  /* background-color: coral; */
  /* width: calc((100% / 3)*2); */
  width: 66%;
  height: 100%;
  flex-shrink: 0;
}

#canvasContainer{
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 20px);
  top: 10px;
  left: 10px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #E0E0E0;
  overflow: hidden;
}

#error_wrapper{
  position: absolute;
  top: 30%;
  left: 20%;
  background-color: #FFFFFF;
  width: 60%;
  height: 40%;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#errorText_wrapper{
  /* background-color: coral; */
  max-width: 330px;
  /* margin: 70px; */
  /* margin: 50px; */
  text-decoration: none;
  color: #333333;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* flex-basis: content; */
}

#errorText_wrapper>p{
  margin-left: 25px;
  margin-top: 0px;
}

#errorIcon{
  position: absolute;
  margin-top: 2px;
}

#tb_wrapper{
  /* background-color: lightblue; */
  width: 100%;
  /* height: 100px; */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
}

.tb_submenuWrapper{
  display: flex;
  width: calc(max-content);
  justify-content: right;
  overflow: hidden;
  transition: width ease 0.3s;
  max-width: calc(100% - 20px);
  overflow: hidden;
}

.tb_submenu{
  /* background-color: coral; */
  width: max-content;
  height: 25px;
  margin-bottom: 5px;
  margin-left: 30px;
  right: 0px;
  margin-right: -5px;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  pointer-events: all;

}

.tb_submenu > .tb_button{
  margin-right: 5px;
}

.tb_button{
  /* position: absolute; */
  background-color: #ffffff;
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  border-radius: 2px;
  outline: solid 1px #E0E0E0;
  outline-offset: -1px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.25s;
  right: 0px;
  flex: 0 0 auto;
  pointer-events: all;
}

.tb_button:hover{
  background-color: #f4f4f4;
}

.tb_button:active{
  background-color: #d1ebff
  ;
}

.tb_uniform{
  background-color: #ffffff;
  width: max-content;
  height: 25px;
  padding: 0 10px;
  border-radius: 2px;
  outline: solid 1px #E0E0E0;
  outline-offset: -1px;
  overflow: hidden;
  transition: background-color 0.25s;
  right: 0px;
  display: flex;
  align-items: center;

  text-decoration: none;
  color: #333333;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.tb_submenu > .tb_uniform{
  margin-right: 5px;
}

.tb_uniformType{
  font-style: italic;
  color: #8859A8;
}
.tb_uniformName{
}
.tb_uniformVal{
  color: #F58720;
  cursor: ns-resize;
  text-decoration: none;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border: none;
  padding: 0;
}
.tb_uniformVal:focus{
  outline: none;
  border: none;
  padding: 0;
  background-color: #E0E0E0;
}
.tb_uniformValTemp{
  position: fixed;
  opacity: 0;
  z-index: -100;
  white-space: nowrap;
  min-width: 1px;
  font-size: 12px;
}

#editPanel{
  box-sizing: border-box;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 10px;
  padding-left: 0px;
  /* background-color: cornflowerblue; */
  /* background-color: #f2f2f2; */
  width: 100%;
  height: 100%;
  /* right: 0px; */
  /* border-left: solid 1px #E0E0E0; */
}

#vBreak{
  width: 10px;
  height: calc(100% - 20px);
  margin: 10px 0px;
}
#vBreak:hover{
  cursor: ew-resize;
}

#hBreak{
  height: 10px;
  width: 100%;
}
#hBreak:hover{
  cursor: ns-resize;
}

.Break{
  background-color: #d1ebff;
  border-radius: 3px;
  flex-shrink: 0;
  box-shadow: inset 0px 0px 0px 3px #f4f4f4;
  opacity: 0;
}
.Break:hover{
  opacity: 0.5;
}

.Break:active{
  opacity: 1;
}

.editorWrap{
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100%;
  /* height: 100%; */
  /* margin: 10px; */
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #E0E0E0;
  overflow: hidden;
}

#vertPanel{
  height: calc(50% - 5px);
}

#fragPanel{
  flex-grow: 1;
}

.editorTitle{
  margin: 5px;
  text-decoration: none;
  color: #333333;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.editor{
  flex-grow: 1;
  border-top: solid 1px #E0E0E0;
}

.ace_scroller.ace_scroll-left{
  box-shadow: none;
  /* box-shadow: 10px 0 16px -16px rgb(0 0 0 / 40%) inset; */
}